import React from 'react'
import { Link } from 'react-router-dom'

export default function EventsSection() {
  return (
    <>
    <section className='event-section'>
      <div className='container-fluid'>
        <div className='row gy-3 gy-lg-0 gy-md-0 align-items-center'>
        <div className='col-md-4 col-lg-3 event-col'>
                <div className='event-content'>
                <div className='top-heading m-md-auto m-lg-0'>
                <img src="red-ball.png"/>
                <span>BACKGROUND</span>
                </div>
                <h2>Events</h2>
                <p>Nullam dictum felis eu pede mollis 
                    pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                    <a className='theme-btn' href="/">See All Events</a>
                </div>
        </div>
        <div className='col-md-8 col-lg-9'>
            <div className='row gy-3 gy-lg-0 gy-md-0'>
                <div className='col-md-6 col-lg-4  event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
                <div className='col-md-6 col-lg-4 event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
                <div className='col-md-6 col-lg-4 event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" alt='...' className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
            </div>
        </div>
        </div>
      </div>
      </section>
    </>
  )
}
