import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';

export default function Extras2() {
    const {mid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/scoreCard/getScoreCard?matchId=`+mid+`&clubId=16685`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          
    
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
                <div className='match-detail-box'>
                <h5>Extras</h5>
             <table className="table">
                    <thead>
                        <tr>
                        <th className='text-center' scope="col">LBs</th>
                        <th  className='text-center' scope="col">NB</th>
                        <th className='text-center' scope="col">WB</th>
                        </tr>
                    </thead>
          {value && 
            Object.values(value.data.innings2).slice(2,3).map((extras,ex) =>{
                    return( 
                    <tbody className='text-center'>
                        <tr>
                        <td>{extras.lb}</td>
                        <td>{extras.nb}</td>
                        <td>{extras.wd}</td>
                        </tr>
                       
                    </tbody>
                  
                    )
            })
          }
            </table>
            </div>
           
         
        </>
    )
}
