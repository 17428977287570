import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'

export default function Inning1() {
    const {mid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/scoreCard/getScoreCard?matchId=`+mid+`&clubId=16685`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
                <div className='inning-detail-box'>
                {value && 
            Object.values(value.data).slice(1,2).map((inni,i) =>{
                    return(    
                      <div>         
                   <h5>{inni.teamName} innings (20 overs maximum) </h5>
                   </div>
                    )
            })
          }
                <table className="table">
                        <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">R</th>
                        <th scope="col">B</th>
                        <th scope="col">4s</th>
                        <th scope="col">6s</th>
                        <th scope="col">SR</th>
                        </tr>
                    </thead>
          {value && 
            Object.values(value.data.innings1.batting).map((inning,i) =>{
                    return(             
                    <tbody>
                        <tr>
                        <td><Link to={'/playerdetail/'+inning.playerID}>{inning.profilepic_file_path ? (
               <img src={'https://cricclubs.com'+inning.profilepic_file_path}/>
               ) : (
                <img src='/dummyimg.png'/>
               )
              }  
                          {inning.firstName} {inning.lastName}</Link></td>
                        <td>{inning.outStringNickNamesNoLink}</td>
                        <td>{inning.runsScored}</td>
                        <td>{inning.ballsFaced}</td>
                        <td>{inning.fours}</td>
                        <td>{inning.sixers}</td>
                        <td>{(inning.runsScored/inning.ballsFaced*100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                   
                    )
            })
          }
            </table>
            </div>
           
         
        </>
    )
}
