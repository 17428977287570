import React from 'react'

export default function Gallery2019() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2019/2019%20(1).jpg'/>
              <img src='/gallery/2019/2019%20(2).jpg'/>
              <img src='/gallery/2019/2019%20(3).jpg'/>
              <img src='/gallery/2019/2019%20(4).jpg'/>
              <img src='/gallery/2019/2019%20(5).jpg'/>
              <img src='/gallery/2019/2019%20(6).jpg'/>
              <img src='/gallery/2019/2019%20(7).jpg'/>
              <img src='/gallery/2019/2019%20(8).jpg'/>
              <img src='/gallery/2019/2019%20(9).jpg'/>
              <img src='/gallery/2019/2019%20(10).jpg'/>
              <img src='/gallery/2019/2019%20(11).jpg'/>
              <img src='/gallery/2019/2019%20(12).jpg'/>
              <img src='/gallery/2019/2019%20(13).jpg'/>
              <img src='/gallery/2019/2019%20(14).jpg'/>
              <img src='/gallery/2019/2019%20(15).jpg'/>
              <img src='/gallery/2019/2019%20(16).jpg'/>
              <img src='/gallery/2019/2019%20(17).jpg'/>
              <img src='/gallery/2019/2019%20(18).jpg'/>
              <img src='/gallery/2019/2019%20(19).jpg'/>
              <img src='/gallery/2019/2019%20(20).jpg'/>
              <img src='/gallery/2019/2019%20(21).jpg'/>
              <img src='/gallery/2019/2019%20(22).jpg'/>
              <img src='/gallery/2019/2019%20(23).jpg'/>
              <img src='/gallery/2019/2019%20(24).jpg'/>

            </div>
          </div>
        </div>
        </div> 
    </>
  )
}

