import React, {useState, useEffect} from 'react'
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';

export default function ResultDetailsCarousel() {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  var setting = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const {sid} = useParams();
  const {tid} = useParams();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?X-Auth-Token=&clubId=16685&seriesId=22&teamId=208`)
      .then((response) => response.json())
      .then((actualData) => setValue(actualData))
      .catch((err) => {
        setError(err.message);
        setValue(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  return (
    <>
    <Slider {...settings} className='result-carousel-sec desk-result'>
    {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}

      {value &&
            Object.values(value.data).map((result, xd)=>{          
              let x = result.t1balls;
              let round = Math.round(x / 6-0.4);
              let mod=x%6;
              let over = parseFloat(round+"."+mod);
              let y = result.t2balls;
              let round2 = Math.round(y / 6-0.4);
              let mod2=y%6;
              let over2 = parseFloat(round2+"."+mod2);
              return(   
                <div>
                  <div className='result-car-col p-3'>
           <div className='result-carousel-box'>
           <Link className='match-info-link' to={'/match-detail/'+result.matchId+'/22/208'}>
            <p className='p-2 text-center'><i className='fa fa-calendar mx-2'></i>{result.matchDate}</p>
            <table>
              <tr>
                <td><img className='result-car-img' alt='...' src={'https://ccapi.cricclubs.com'+result.t1_logo_file_path}/> {result.teamOneName}</td>
                <td className='result-carousel-score'>{result.t1total+'/'+result.t1wickets} ({over+' ov'})</td>
              </tr>
              <tr>
              <td><img className='result-car-img' alt='...' src={'https://ccapi.cricclubs.com'+result.t2_logo_file_path}/> {result.teamTwoName}</td>
                <td className='result-carousel-score'>{result.t2total+'/'+result.t2wickets} ({over2+' ov'})</td>
              </tr>
            </table>
            <p className='result-rr text-center'>{result.result}</p>
            </Link>
            </div>
            </div>
            </div>
              );
              
            })
          }
           
    
      
    </Slider>

    <Slider {...setting} className='result-carousel-sec mob-result'>
    {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}

      {value &&
            Object.values(value.data).map((result, xd)=>{          
              let x = result.t1balls;
              let round = Math.round(x / 6-0.4);
              let mod=x%6;
              let over = parseFloat(round+"."+mod);
              let y = result.t2balls;
              let round2 = Math.round(y / 6-0.4);
              let mod2=y%6;
              let over2 = parseFloat(round2+"."+mod2);
              return(   
                <div>
                  <div className='result-car-col p-3'>
           <div className='result-carousel-box'>
           <Link className='match-info-link' to={'/match-detail/'+result.matchId+'/22/208'}>
            <p className='p-2 text-center'><i className='fa fa-calendar mx-2'></i>{result.matchDate}</p>
            <table>
              <tr>
                <td><img className='result-car-img' alt='...' src={'https://ccapi.cricclubs.com'+result.t1_logo_file_path}/> {result.teamOneName}</td>
                <td className='result-carousel-score'>{result.t1total+'/'+result.t1wickets} ({over+' ov'})</td>
              </tr>
              <tr>
              <td><img className='result-car-img' alt='...' src={'https://ccapi.cricclubs.com'+result.t2_logo_file_path}/> {result.teamTwoName}</td>
                <td className='result-carousel-score'>{result.t2total+'/'+result.t2wickets} ({over2+' ov'})</td>
              </tr>
            </table>
            <p className='result-rr text-center'>{result.result}</p>
            </Link>
            </div>
            </div>
            </div>
              );
              
            })
          }   
    </Slider>
    </>
  );
}
