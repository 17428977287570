import React from 'react'

export default function ComingSoon() {
  return (
    <>
    <div className='coming-soon-sec'>
      <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <h2 className='coming-soon-text'>Coming Soon</h2>
                </div>
            </div>
      </div>
      </div>
    </>
  )
}
