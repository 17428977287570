import React from 'react'
import { useState,useEffect } from 'react';
import moment from 'moment'

export default function UpcomingMatches() {

    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=19323&seriesId=&teamId=`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

  return (
    <>
      <div className='upcoming-matches'>
        <div className='container'>
        {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
            <div className='row'>
                <div className='col-md-12'>
                <div className='top-heading m-auto'>
                <img src="red-ball.png"/>
                <span>WHAT'S TRENDING</span>
                </div>
                <h2>Upcoming Next Match</h2>
                <p className='up-p py-2'>Stay tuned for more updates on our upcoming matches and be sure to join us at the ground to cheer on Maverickcc!</p>
              
        <div className='row py-5 gy-3 gy-md-0 gy-lg-0'>

        {value &&
            Object.values(value.data.fixtureList).slice(0,3).sort().map((schedule, xd)=>{
              return(
            <div className='col-md-6 col-lg-4'>
                <div className='upcoming-match-box'>
                    <h3 className='nxt-match-text py-1'>Next Match</h3>
                    <p className='date-text'>{schedule.day}</p>
                    <p className='date-text'>{moment(schedule.date).format('Do').replace(/(\d+)(st|nd|rd|th)/, "$1")}  {moment(schedule.date).format('MMM YYYY')} - {schedule.time}</p>
                    <div className='row align-items-center my-4'>
                      <div className='col-5'>
                        <img src={'https://cricclubs.com'+schedule.t1_logo_file_path}/>
                      </div>
                      <div className='col-2'>
                        <p><strong>VS</strong></p>
                      </div>
                      <div className='col-5'>
                        <img src={'https://cricclubs.com'+schedule.t2_logo_file_path}/>
                      </div>
                    </div>
                    <p className='location-text'>Live At {schedule.location}</p>
                </div>
            </div>
            )})  
          }
        </div>
              
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
