import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';

export default function ANCLResult() {
    const {sid} = useParams();
    const {tid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?X-Auth-Token=&clubId=16685&seriesId=`+sid+`&teamId=`+tid)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="/red-ball.png"/>
                <span>SERIES</span>
                </div>
            {value && Object.values(value.data).slice(0,1).map((res, x)=>{
                return(
                    <h1 className='text-center mb-4'><strong>{res.seriesName}</strong></h1>
                );
            })}
            
             <div className='row'>
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          <div className='container'>
          <div className='row justify-content-center result-sec text-center'>
          {value &&
            Object.values(value.data).map((result, xd)=>{
                
              let x = result.t1balls;
              let round = Math.round(x / 6-0.4);
              let mod=x%6;
              let over = parseFloat(round+"."+mod);
              let y = result.t2balls;
              let round2 = Math.round(y / 6-0.4);
              let mod2=y%6;
              let over2 = parseFloat(round2+"."+mod2);
              return(   
                
                <div className='col-lg-8 col-12 mb-5 result-col'>
                  <Link className='match-info-link' to={'/match-detail/'+result.matchId+'/'+sid+'/'+tid}>                    <div className='row align-items-center'>
                        <h4>{result.matchDate}</h4>
                        <p className='league-text'>{result.seriesName}</p>
                        <div className='col-lg-3 col-3'>
                            <p className='team-name'>{result.teamOneName}</p>
                            <img alt='...' src={'https://ccapi.cricclubs.com'+result.t1_logo_file_path}/>
                        </div>
                        <div className='col-lg-2 col-2'>  
                            <h5 className='score-text'>{result.t1total+'/'+result.t1wickets}</h5>
                            <p className='over-text'>{over}</p>
                        </div>
                        <div className='col-lg-2 col-2 d-flex align-items-center justify-content-center text-center'>
                            <strong className='vs'>VS</strong>
                        </div>
                        <div className='col-lg-2 col-2'>
                            <h5 className='score-text'>{result.t2total+'/'+result.t2wickets}</h5>
                            <p className='over-text'>{over2}</p>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <p className='team-name'>{result.teamTwoName}</p>
                            <img alt='...' src={'https://ccapi.cricclubs.com'+result.t2_logo_file_path}/>
                        </div>
                        <strong className='win-text'>{result.result}</strong>
                        <Link className='match-info-link' to={'/match-detail/'+result.matchId+'/'+sid+'/'+tid}>Match Information</Link>
                    </div>
                    </Link>
                </div>  
           
               
              );
              
            })
          }
          </div>
          </div>
          </div>
          </div>
        </>
    )
}
