import React from 'react'

export default function Gallery2015() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2015/2015-1.jpg'/>
              <img src='/gallery/2015/2015-2.jpg'/>
              <img src='/gallery/2015/2015-3.jpg'/>
              <img src='/gallery/2015/2015-4.jpg'/>
              <img src='/gallery/2015/2015-5.jpg'/>
              <img src='/gallery/2015/2015-6.jpg'/>
              <img src='/gallery/2015/2015-7.jpg'/>
              <img src='/gallery/2015/2015-8.jpg'/>
              <img src='/gallery/2015/2015-9.jpg'/>
              <img src='/gallery/2015/2015-10.jpg'/>
              <img src='/gallery/2015/2015-11.jpg'/>
              <img src='/gallery/2015/2015-12.jpg'/>
              <img src='/gallery/2015/2015-13.jpg'/>
              <img src='/gallery/2015/2015-14.jpg'/>
              <img src='/gallery/2015/2015-15.jpg'/>
              <img src='/gallery/2015/2015-16.jpg'/>
              <img src='/gallery/2015/2015-17.jpg'/>
              <img src='/gallery/2015/2015-18.jpg'/>
              <img src='/gallery/2015/2015-19.jpg'/>
              <img src='/gallery/2015/2015-20.jpg'/>
              <img src='/gallery/2015/2015-21.jpg'/>
              <img src='/gallery/2015/2015-22.jpg'/>
            </div>
          </div>
        </div>
        </div> 
    </>
  )
}
