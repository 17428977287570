import React from 'react'

export default function AboutSection() {
  return (
    <>
        <div className='about-us-section'>
            <div className='container'>
                <div className='row'>
                    
                    <div className='col-md-6'>
                        <div className='top-heading'>
                            <img src="red-ball.png"/>
                            <span>INTRODUCTION</span>
                        </div>
                        <h2>About Mavericks</h2>
                        <p>Mavericks Cricket Club was formed in 2007 by a bunch of cricket enthusiasts 
                            and a group of young men of diverse descent and backgrounds, who had played the game of cricket
                            at different levels. Now under Mavericks Sports Foundation (a registered non-profit organization), 
                            Mavericks players has participated in Washington DC-based cricket leagues over the years.
                            tournaments in and around Washington DC, Virginia, and Maryland. Mavericks are excited and
                            proud to create healthy sporting opportunities for young and adults to participate in sports.
                            As of 2022 we have 95 players representing Mavericks.</p>
                            <p>As Sports Foundations, we are excited to create opportunities for youth and adult players.</p>
                    </div>
                    <div className='col-md-6 img-col'>
                        <img src="img-1.jpg" />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
