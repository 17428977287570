import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

export default function ANCLSeries() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/series/getSeriesList?X-Auth-Token=&clubId=16685&limit=`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    const {customvalue} = () => {
        Object.values(value.data.seriesList).map((seriesit, x) => {
            console.log(seriesit.seriesName);
        })
    }

    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="red-ball.png"/>
                <span>SERIES</span>
                </div>
            <h1 className='text-center mb-4'><strong>ANCL Series Maverickscc Played</strong></h1>
             <div className='row'>
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          {value &&
            Object.values(value.data.seriesList).map((seriesitems, xd)=>{
              return(   
                  <div className='col-lg-6 my-3'>
                    <div className='series-box'>
                   
                    {(() => {
                            switch (seriesitems.seriesID) {
                             
                              case 12:
                                return (
                                  <Link key={xd} to="/anclresult/12/123">
                                  <h3>{seriesitems.seriesName}</h3>
                                  <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                  <p><strong>Year: </strong>{seriesitems.year}</p>
                                  <span>{xd+1}</span>
                                  </Link>
                                );
                              case 6:
                                return(
                                  <Link key={xd} to="/anclresult/6/67">
                                  <h3>{seriesitems.seriesName}</h3>
                                  <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                  <p><strong>Year: </strong>{seriesitems.year}</p>
                                  <span>{xd+1}</span>
                                  </Link>
                                );
                                case 1:
                                  return(
                                    <Link key={xd} to="/anclresult/1/10">
                                    <h3>{seriesitems.seriesName}</h3>
                                    <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                    <p key={xd}><strong>Year: </strong>{seriesitems.year}</p>
                                    <span>{xd+1}</span>
                                    </Link>
                                  );
                                  case 11:
                                    return(
                                      <Link key={xd} to="/anclresult/11/115">
                                      <h3>{seriesitems.seriesName}</h3>
                                      <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                      <p><strong>Year: </strong>{seriesitems.year}</p>
                                      <span>{xd+1}</span>
                                      </Link>
                                    );
                                    case 10:
                                      return(
                                        <Link key={xd} to="/anclresult/10/105">
                                        <h3>{seriesitems.seriesName}</h3>
                                        <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                        <p><strong>Year: </strong>{seriesitems.year}</p>
                                        <span>{xd+1}</span>
                                        </Link>
                                      );
                                      case 7:
                                        return(
                                          <Link key={xd} to="/anclresult/7/93">
                                          <h3>{seriesitems.seriesName}</h3>
                                          <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                          <p><strong>Year: </strong>{seriesitems.year}</p>
                                          <span>{xd+1}</span>
                                          </Link>
                                        );
                                        case 5:
                                        return(
                                          <Link key={xd} to="/anclresult/5/59">
                                          <h3>{seriesitems.seriesName}</h3>
                                          <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                          <p><strong>Year: </strong>{seriesitems.year}</p>
                                          <span>{xd+1}</span>
                                          </Link>
                                        );
                                        case 2:
                                        return(
                                          <Link key={xd} to="/anclresult/2/32">
                                          <h3>{seriesitems.seriesName}</h3>
                                          <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                          <p><strong>Year: </strong>{seriesitems.year}</p>
                                          <span>{xd+1}</span>
                                          </Link>
                                        );
                                        case 4:
                                        return(
                                          <div key={xd}>
                                          <h3>{seriesitems.seriesName}</h3>
                                          <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                          <p><strong>Year: </strong>{seriesitems.year}</p>
                                          <span>{xd+1}</span>
                                          </div>
                                        );
                                        case 3:
                                        return(
                                          <div key={xd}>
                                          <h3>{seriesitems.seriesName}</h3>
                                          <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                          <p><strong>Year: </strong>{seriesitems.year}</p>
                                          <span>{xd+1}</span>
                                          </div>
                                        );
                                        case 20:
                                          return(
                                            <div key={xd}>
                                            <h3>{seriesitems.seriesName}</h3>
                                            <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                            <p><strong>Year: </strong>{seriesitems.year}</p>
                                            <span>{xd+1}</span>
                                            </div>
                                          );
                                        case 21:
                                          return(
                                            <div key={xd}>
                                            <h3>{seriesitems.seriesName}</h3>
                                            <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                            <p><strong>Year: </strong>{seriesitems.year}</p>
                                            <span>{xd+1}</span>
                                            </div>
                                          );
                                          case 22:
                                          return(
                                            <Link key={xd} to="/anclresult/22/208">
                                            <div key={xd}>
                                            <h3>{seriesitems.seriesName}</h3>
                                            <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                            <p><strong>Year: </strong>{seriesitems.year}</p>
                                            <span>{xd+1}</span>
                                            </div>
                                            </Link>
                                          );
                                          case 23:
                                          return(
                                            <div key={xd}>
                                            <h3>{seriesitems.seriesName}</h3>
                                            <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                            <p><strong>Year: </strong>{seriesitems.year}</p>
                                            <span>{xd+1}</span>
                                            </div>
                                          );
                                          case 24:
                                            return(
                                              <Link key={xd} to="/anclresult/24/220">
                                              <div key={xd}>
                                              <h3>{seriesitems.seriesName}</h3>
                                              <p><strong>Series Type: </strong>{seriesitems.seriesType}</p>
                                              <p><strong>Year: </strong>{seriesitems.year}</p>
                                              <span>{xd+1}</span>
                                              </div>
                                              </Link>
                                            );
                              default:
                                return null
                            }
                          })()}
                   
                    </div>
                    
                    
              </div>

               
              );
            })
          }
          </div>
          </div>
        </>
    )
}
