import React from 'react'

export default function Subheader(props) {
  return (
    <>
    <div className='sub-header'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <h1>{props.pagetitle}</h1>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
