import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';

export default function SinglePlayerDetail() {
    const {id} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/user/getUserDetails?playerId=`+id)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          <div className='container my-5'>
             <div className='row justify-content-center player-main-row'>
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          {value &&
            Object.values(value).slice(0,1).map((data)=>{
              return(   
                <div className='col-lg-8'>
              <div className='row player-detail-row'>
                <div className='col-lg-4'>
                {data.profileImagePath ? (
               <img src={'https://cricclubs.com'+data.profileImagePath}  className="player-img"/>
               ) : (
                <img src='/dummyimg.png' className="player-img"/>
               )
              }    
               <h3>{data.firstName} {data.lastName}</h3>
                </div>
                <div className='col-lg-8'>
                 <div className='row spec-row text-center'>
                  <div className='col-lg-6'>
                  <img src='/user-icon.png'/>
                        <h4>Player ID</h4>
                        <p>{data.playerID}</p>
                  </div>
                  <div className='col-lg-6'>
                  <img src='/cricket-icon.png'/>
                        <h4>Player Role</h4>
                        <p>{data.playingRole}</p>
                  </div>
                  </div>
                  
                  <div className='row spec-row text-center'>
                  <div className='col-lg-6'>
                  <img src='/bat-icon.png'/>
                        <h4>Batting Style</h4>
                        <p>{data.battingStyle}</p>
                  </div>
                  <div className='col-lg-6'>
                  <img src='/ball.png'/>
                        <h4>Bowling Style</h4>
                        <p>{data.bowlingStyle}</p>
                  </div>
                  </div>

                </div>
              </div>
           </div>
                );
            })
          }
          
          </div>
          </div>
        </>
    )

}
