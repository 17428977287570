import React from 'react'

export default function Gallery2012() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2012/2012-1.jpg'/>
              <img src='/gallery/2012/2012-2.jpg'/>
              <img src='/gallery/2012/2012-3.jpg'/>
              <img src='/gallery/2012/2012-4.jpg'/>
              <img src='/gallery/2012/2012-5.jpg'/>
              <img src='/gallery/2012/2012-6.jpg'/>
              <img src='/gallery/2012/2012-7.jpg'/>
              <img src='/gallery/2012/2012-8.jpg'/>
              <img src='/gallery/2012/2012-9.jpg'/>
              <img src='/gallery/2012/2012-10.jpg'/>
              <img src='/gallery/2012/2012-11.jpg'/>
              <img src='/gallery/2012/2012-12.jpg'/>
            </div>
          </div>
        </div>
        </div> 
    </>
  )
}
