import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
// import { Link } from 'react-router-dom'

export default function FullMatchDetailHead(props) {
    const {sid} = useParams();
    const {tid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?X-Auth-Token=&clubId=16685&seriesId=`+sid+`&teamId=`+tid)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    console.log(props.matchid);
    

    return (
        <>
            {loading && <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
            
            {value &&
                Object.values(value.data).map((result, xd) => {
                    let x = result.t1balls;
                    let round = Math.round(x / 6 - 0.4);
                    let mod = x % 6;
                    let over = parseFloat(round + "." + mod);
                    let y = result.t2balls;
                    let round2 = Math.round(y / 6 - 0.4);
                    let mod2 = y % 6;
                    let over2 = parseFloat(round2 + "." + mod2);
                    
                   if(result.matchId == props.matchid)
                   {
                    return (
                        <div class="row fullmatch-subheader justify-content-center mt-5">
                            <div class="col-lg-3 col-6 first-team">
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-4">
                                    <img alt='...' src={'https://ccapi.cricclubs.com'+result.t1_logo_file_path}/>
                                    </div><div class="col-lg-6 col-8">
                                        <p class="teamname">{result.teamOneName}</p>
                                        <p className='totalscore'>{result.t1total+'/'+result.t1wickets}</p>
                                        <p className='totalover'>{over} ov</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-6 second-team">
                                <div class="row align-items-center text-end">
                                    <div class="col-lg-6 col-8">
                                        <p class="teamname">{result.teamTwoName}</p>
                                        <p className='totalscore'>{result.t2total+'/'+result.t2wickets}</p>
                                        <p className='totalover'>{over2} ov</p>
                                    </div>
                                    <div class="col-lg-6 col-4">
                                    <img alt='...' src={'https://ccapi.cricclubs.com'+result.t2_logo_file_path}/>
                                    </div>
                                </div>
                            </div>
                            <h5 className='text-center mt-3'>{result.seriesName}   -   {result.result}
                            </h5>
                        </div>
                    );
                   }
                   else{
                    return(<div></div>);
                   }

                })
            }

        </>
    )
}
