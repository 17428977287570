import React from 'react'
import { useState } from 'react'

export default function ContactForm() {
    
    const [state, setState] = useState({
        fullname: "",
        email: "",
        phone: "",
        purpose: "",
        message: ""
      })
      function handleChange(evt) {
        const value = evt.target.value;
        setState({
          ...state,
          [evt.target.name]: value
        });
      }
      const handleSubmit = (e) => {
        e.preventDefault();
        alert('Full Name',state.fullname);
        console.log('Full Name',state.fullname);
    }
  return (
    <>
    <section className='contact-section py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h2>Contact us for more queries</h2>
                    <p className='pb-3'>Nullam dictum felis eu pede mollis pretium.
                         Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. </p>
                         <ul className='footer-links'>
            <li><i className='fas fa-phone px-2'></i><a href='tel:+15712250046'>+1 (571) 225-0046</a></li>
            <li><i className='fas fa-envelope px-2'></i><a href='mailto:admin@maverickscc.com'>admin@maverickscc.com</a></li>
            <li><i className='fas fa-globe px-2'></i>Washington, DC</li>
          </ul>
                </div>
                <div className='col-lg-6'>
                    <form className='contact-form' onSubmit={handleSubmit}>
                    <div className='row'>
                    <div className="col-lg-6">
                        <label htmlFor="fullname" className="form-label">Full Name</label>
                        <input name='fullname' type="text" value={state.fullname} onChange={handleChange} className="form-control" id="fullname" placeholder="your full name"/>
                     </div>
                     <div className="col-lg-6">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                        <input name='email' type="email" value={state.email} onChange={handleChange} className="form-control" id="exampleFormControlInput1" placeholder="your email address"/>
                     </div>
                     </div>
                     <div className='row my-3'>
                    <div className="col-lg-6">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <input name='phone' type="text" value={state.phone} onChange={handleChange} className="form-control" id="phone" placeholder="your phone number"/>
                     </div>
                     <div className="col-lg-6">
                        <label htmlFor="purpose" className="form-label">Purpose</label>
                        <select name='purpose' className="form-select" value={state.purpose} onChange={handleChange} aria-label="Select Purpose">
                            <option value='want to join club?'>Want to join club?</option>
                            <option value='Report a problem'>Report a problem</option>
                            <option value='Other'>Other</option>
                        </select>
                     </div>
                     
                    </div>
                    <div className="my-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                        <textarea name='message' value={state.message} onChange={handleChange} className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='your message'/>
                    </div>
                    <input type='submit' className='form-btn' value='Submit' />
                    </form>
                </div>
            </div>
            
        </div>
    </section>
    </>
  )
}
