import React from 'react'

export default function Gallery2008() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2008/2008-1.jpg'/>
              <img src='/gallery/2008/2008-2.jpg'/>
              <img src='/gallery/2008/2008-3.jpg'/>
              <img src='/gallery/2008/2008-4.jpg'/>
              <img src='/gallery/2008/2008-5.jpg'/>
              <img src='/gallery/2008/2008-6.jpg'/>
              <img src='/gallery/2008/2008-7.jpg'/>
              <img src='/gallery/2008/2008-8.jpg'/>
              <img src='/gallery/2008/2008-9.jpg'/>
              <img src='/gallery/2008/2008-10.jpg'/>
              <img src='/gallery/2008/2008-11.jpg'/>
            </div>
          </div>
        </div>
        </div> 
    </>
  )
}
