import React from 'react'
import {Link} from 'react-router-dom'

export default function Gallery() {
  return (
    <>
        <div className='container my-5'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                <Link to='/2008'>
                  <img src='2008-main.jpg'/>

                  <h4>2008</h4>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                <Link to='/2009'>
                  <img src='2009-main.jpg'/>
                  <h4>2009</h4>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                  <Link to='/2012'>
                  <img src='2012-main.jpg'/>
                  <h4>2012</h4>
                  </Link>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                  <Link to='/2013'>
                  <img src='2013-main.jpg'/>
                  <h4>2013</h4>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                  <Link to='/2014'>
                  <img src='2014-main.jpg'/>
                  <h4>2014</h4>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                  <Link to='/2015'>
                  <img src='2015-main.jpg'/>
                  <h4>2015</h4>
                  </Link>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                  <Link to='/2019'>
                  <img src='2019-main.jpg'/>
                  <h4>2019</h4>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 p-3'>
                <div className='gallery-main-box'>
                <Link to='/2022'>
                  <img src='2022-main.jpg'/>
                  <h4>2022</h4>
                  </Link>
                </div>
              </div>
            </div>
        </div>
    </>
  )
}
