import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';


export default function ANCL2022YearSchedule() {
   
    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="/red-ball.png"/>
                <span>2022 Schedule</span>
                </div>
            <h1 className='text-center mb-4'><strong>ANCL Schedule For Maverickscc </strong></h1>
             <div className='row justify-content-center'>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/11/115">
                <h3>2022-T20-PLAYOFFS</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2022</p>
                <p><strong>Start Date: </strong>09/29/2022</p>
                <span></span>
                </Link>
                </div>
                </div>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/7/93">
                <h3>2022-T20-LEAGUE</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2022</p>
                <p><strong>Start Date: </strong>04/16/2022</p>
                <span></span>
                </Link>
                </div>
                </div>                               
            </div>

            <div className='row justify-content-center'>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/12/123">
                <h3>2022-REDZOD-40-OVER-PLAYOFFS</h3>
                <p><strong>Series Type: </strong>One Day</p>
                <p><strong>Year: </strong>2022</p>
                <p><strong>Start Date: </strong>10/14/2022</p>
                <span></span>
                </Link>
                </div>
                </div>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/10/105">
                <h3>REDZOD 40 OVER CRICKET LEAGUE 2022</h3>
                <p><strong>Series Type: </strong>One Day</p>
                <p><strong>Year: </strong>2022</p>
                <p><strong>Start Date: </strong>07/09/2022</p>
                <span></span>
                </Link>
                </div>
                </div>                               
            </div>
             
          </div>
        </>
    )
}
