import React from 'react'

export default function Gallery2022() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2022/2022%20(1).jpg'/>
              <img src='/gallery/2022/2022%20(2).jpg'/>
              <img src='/gallery/2022/2022%20(3).jpg'/>
              <img src='/gallery/2022/2022%20(4).jpg'/>
              <img src='/gallery/2022/2022%20(5).jpg'/>
              <img src='/gallery/2022/2022%20(6).jpg'/>
              <img src='/gallery/2022/2022%20(7).jpg'/>
              <img src='/gallery/2022/2022%20(8).jpg'/>
              <img src='/gallery/2022/2022%20(9).jpg'/>
              <img src='/gallery/2022/2022%20(10).jpg'/>
              <img src='/gallery/2022/2022%20(11).jpg'/>
              <img src='/gallery/2022/2022%20(12).jpg'/>
              <img src='/gallery/2022/2022%20(13).jpg'/>

            </div>
          </div>
        </div>
        </div> 
    </>
  )
}

