import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';


export default function ANCL2023YearSchedule() {
   
    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="/red-ball.png"/>
                <span>2023 Schedule</span>
                </div>
            <h1 className='text-center mb-4'><strong>ANCL Schedule For Maverickscc </strong></h1>
             <div className='row justify-content-center'>      
             <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/24/220">
                <h3>2023-T20-Playoffs</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2023</p>
                <p><strong>Start Date: </strong>20/05/2023</p>
                <span></span>
                </Link>
                </div>
                </div>

                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/22/208">
                <h3>2023-T20-LEAGUE</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2023</p>
                <p><strong>Start Date: </strong>04/05/2023</p>
                <span></span>
                </Link>
                </div>
                </div>     
                          
            </div>
            <div className='row justify-content-center'>      
              
            <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/comingsoon">
                <h3>2023-T40-LEAGUE</h3>
                <p><strong>Series Type: </strong>One Day</p>
                <p><strong>Year: </strong>2023</p>
                <p><strong>Start Date: </strong>06/10/2023</p>
                <span></span>
                </Link>
                </div>
                </div>    
                <div className='col-lg-5 my-3'>
                  </div>   
                                           
            </div>
             
          </div>
        </>
    )
}
