import React, {useState, useEffect} from 'react'
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';

export default function Sponsors() {

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow:5,
        slidesToScroll: 1
      };

      var setting = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
      };
    
      const {sid} = useParams();
      const {tid} = useParams();
      const [value, setValue] = useState(null);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);


  return (
      <>
      <div className='sponsor-section'>
      <div className='top-heading m-auto'>
                <img src="red-ball.png"/>
                <span>Sponsors</span>
                </div>
      <h3>Our Sponsors</h3>
      <Slider {...settings} className='sponsor-slider'>
        <div>
        <img src='sponsor%20(1).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(2).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(3).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(4).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(5).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(6).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(7).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(1).png'/>
        </div>
        
      </Slider>

      <Slider {...setting} className='sponsor-slider mobile-sponsor-slider'>
        <div>
        <img src='sponsor%20(1).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(2).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(3).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(4).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(5).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(6).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(7).jpg'/>
        </div>
        <div>
        <img src='sponsor%20(1).png'/>
        </div>
        
      </Slider>
      </div>
  

    </>
  )
}
