import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';

export default function Inning1total() {
    const {mid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/scoreCard/getScoreCard?matchId=`+mid+`&clubId=16685`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);


    return (
        <>
          
    
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
                <div className='match-detail-box mb-3'>
                <h5>2nd Inning Score</h5>
          {value && 
            Object.values(value.data).slice(1,2).map((total,tot) =>{
                let wickets= total.fallOfWickets.slice(0).length;
                    return( 
                        <div className='row'>
                        <div className='col-lg-6 col-6 mt-3'>
                         <h3 className='text-end totl-head'>Total: <span>{total.total} / {wickets}</span></h3>
                        </div>
                        <div className='col-lg-6 col-6 mt-3'>
                         <h3 className='text-start totl-head'><span>{total.overs}</span> Overs</h3>
                        </div>
                        
                        <h3 className='text-center run-rate pb-2'>Run Rate: <span>{total.runRate}</span></h3>
                        </div>
                    )
                   
            })
          }
           

      </div>
           
         
        </>
    )
}
