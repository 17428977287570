import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import Header from './header_components/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './page_components/Home'; 
import Gallery from './page_components/Gallery';
import Gallery2008 from './page_components/Gallery2008';
import Gallery2009 from './page_components/Gallery2009';
import Gallery2012 from './page_components/Gallery2012';
import Gallery2013 from './page_components/Gallery2013';
import Gallery2015 from './page_components/Gallery2015';
import Subheader from './header_components/Subheader';
import AboutSection from './page_components/AboutSection';
import UpcomingMatches from './page_components/UpcomingMatches';
import Slider from './header_components/Slider';
import EventsSection from './page_components/EventsSection';
import Footer from './page_components/Footer';
import ANCLTeam from './team_details/ANCLTeam';
import ANCLSeries from './page_components/ANCLSeries';
import SinglePlayerDetail from './team_details/SinglePlayerDetail';
import ANCLSeriesDetails from './page_components/ANCLSeriesDetails';
import ANCLSchedule from './schedule_components/ANCLSchedule';
import ComingSoon from './page_components/ComingSoon';
import Achievements from './page_components/Achievements';
import ContactForm from './page_components/ContactForm';
import ANCLResult from './page_components/ANCLResult';
import Gallery2014 from './page_components/Gallery2014';
import Gallery2022 from './page_components/Gallery2022';
import Gallery2019 from './page_components/Gallery2019';
import Blog1 from './page_components/Blog1';
import ANCLScheduleMatches from './schedule_components/ANCLScheduleMatches';
import ANCL2021YearSchedule from './schedule_components/ANCL2021YearSchedule';
import ANCL2022YearSchedule from './schedule_components/ANCL2022YearSchedule';
import ANCL2023YearSchedule from './schedule_components/ANCL2023YearSchedule';
import Full_Match_Details from './match_details/Full_Match_Details';
import ScrollToTop from './header_components/ScrollToTop';
import News from './page_components/News';
import ResultDetailsCarousel from './page_components/ResultDetailsCarousel';
import UserRegistration from './Admin/UserRegistration';
import SimpleContainer from './Admin_Panel/SimpleContainer';
import Sponsors from './page_components/Sponsors';
function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
   <>
 <BrowserRouter>
 <Header/>
 <ScrollToTop>
      <Routes>
        
          <Route path='/' element={(<><Slider /><ResultDetailsCarousel/><AboutSection/><Home /><UpcomingMatches/><EventsSection/><Sponsors/> </>)} />
          <Route path="gallery" element={(<><Subheader pagetitle="Gallery" /> <Gallery /></>)} />
          <Route path='redzod-team' element={(<><Subheader pagetitle="Redzod Team"/> <ANCLTeam/></>)} />
          <Route path='anclseries' element={(<><Subheader pagetitle="ANCL Series"/> <ANCLSeries /></>)} />
          <Route path='/playerdetail/:id' element={(<><Subheader pagetitle="Player Detail"/> <SinglePlayerDetail /></>)} />
          <Route path='/anclseriesdetails/:sid' element={(<><Subheader pagetitle="Series Detail"/> <ANCLSeriesDetails /></>)} />
          <Route path='schedule' element={(<><Subheader pagetitle="Schedule"/> <ANCLSchedule/></>)}/>
          <Route path='achievements' element={(<><Subheader pagetitle="Achievements"/> <ComingSoon/> <Achievements/></>)}/>
          <Route path='contact' element={(<><Subheader pagetitle="Contact"/> <ContactForm/> </>)} />
          <Route path='anclresult/:sid/:tid' element={(<><Subheader pagetitle='ANCL Series Result'/><ANCLResult/></>)}/>
          <Route path='2008' element={(<><Subheader pagetitle='2008'/> <Gallery2008/></>)}/>
          <Route path='2009' element={(<><Subheader pagetitle='2009'/> <Gallery2009/></>)}/>
          <Route path='2012' element={(<><Subheader pagetitle='2012'/> <Gallery2012/></>)}/>
          <Route path='2015' element={(<><Subheader pagetitle='2015'/> <Gallery2015/></>)}/>
          <Route path='2013' element={(<><Subheader pagetitle='2013'/> <Gallery2013/></>)}/>
          <Route path='2014' element={(<><Subheader pagetitle='2014'/> <Gallery2014/></>)}/>
          <Route path='2019' element={(<><Subheader pagetitle='2019'/> <Gallery2019/></>)}/>
          <Route path='2022' element={(<><Subheader pagetitle='2022'/> <Gallery2022/></>)}/>
          <Route path='2022' element={(<><Subheader pagetitle='2022'/> <Blog1/></>)}/>
          <Route path='schedule-matches/:seriesid/:teamid' element={(<><Subheader pagetitle='Schedule Matches'/> <ANCLScheduleMatches/></>)}/>
          <Route path='2021-series-schedule/:ssy' element={(<><Subheader pagetitle='2021 Schedule'/><ANCL2021YearSchedule/></>)}/>
          <Route path='2022-series-schedule/:ssy' element={(<><Subheader pagetitle='2022 Schedule'/><ANCL2022YearSchedule/></>)}/>
          <Route path='2023-series-schedule/:ssy' element={(<><Subheader pagetitle='2023 Schedule'/><ANCL2023YearSchedule/></>)}/>
          <Route path='schedule-matches/comingsoon' element={(<><Subheader pagetitle='2023 Schedule'/><ComingSoon/></>)}/>
          <Route path='/match-detail/:mid/:sid/:tid' element={(<><Subheader pagetitle='Match Detail'/><Full_Match_Details/></>)}/>
          <Route path='news' element={(<><Subheader pagetitle='News & Updates'/><News/></>)}/>
          <Route path='blog-1' element={(<><Subheader pagetitle='Blog 1'/><Blog1/></>)}/>
          <Route path='insert-user' element={(<><Subheader pagetitle='User Registration'/><UserRegistration/></>)}/>
          <Route path='simple' element={(<><SimpleContainer/></>)}/>
      </Routes>
      <Footer/>
      </ScrollToTop>
    </BrowserRouter>
    </>
  );
}

export default App;
