import React from 'react'
import { Link } from 'react-router-dom'

export default function ANCLSchedule() {
  return (
    <>
     <div className='container'>
      <div className='row justify-content-center py-5 s-box'>
        {/* <marquee direction='left' className='mb-5'>2023 Schedule Coming Soon - 2023 Schedule Coming Soon - 2023 Schedule Coming Soon - 2023 Schedule Coming Soon - 2023 Schedule Coming Soon - 2023 Schedule Coming Soon</marquee> */}
        <div className='col-lg-2 mb-4'>
          <div className='year-box text-center'>
            <img src='bat-ball.png'/>
            <Link style={{'textDecoration':'none'}} to='/2021-series-schedule/2021'><h2>2021</h2></Link>
          </div>
        </div>
        <div className='col-lg-2 mb-4'>
          <div className='year-box text-center'>
            <img src='bat-ball.png'/>
            <Link style={{'textDecoration':'none'}} to='/2022-series-schedule/2022'><h2>2022</h2></Link>
          </div>
        </div>
        <div className='col-lg-2 mb-4'>
          <div className='year-box text-center'>
            <img src='bat-ball.png'/>
            <Link style={{'textDecoration':'none'}} to='/2023-series-schedule/2023'><h2>2023</h2></Link>
          </div>
          {/* <p className='text-center'>Coming Soon</p> */}
        </div>
      </div>
     </div>
    </>
  )
}
