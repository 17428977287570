import React from 'react'
import { useState, useEffect } from 'react';
import moment from 'moment'
import { useParams } from 'react-router-dom';

export default function ANCLScheduleMatches() {
  const {seriesid, teamid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=19323&seriesId=`+seriesid+`&teamId=`+teamid)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);


  return (
    <>
      <div className='container'>
        <div className='row py-5 justify-content-center'>
        {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          {value &&
            Object.values(value.data.fixtureList).map((schedule, xd)=>{
                return(
        <div className='col-lg-5 mb-4'>
            <div className='schedule-box'>
                <div className='row align-items-center p-row'>
                    <div className='col-lg-3 col-3 date-box'> 
                        <p>{schedule.day}</p>
                        <h4>{moment(schedule.date).format('Do').replace(/(\d+)(st|nd|rd|th)/, "$1")}</h4>
                        <p>{moment(schedule.date).format('MMM YYYY')}<br/>{schedule.time}</p>
                    </div>
                    <div className='col-lg-9 col-9 p-0'>
                    <div className='teams-box'>
                    <img src={'https://cricclubs.com'+schedule.t1_logo_file_path}/> {schedule.teamOneName}
                    <span className='vs align-self-center'>vs</span>
                    <img src={'https://cricclubs.com'+schedule.t2_logo_file_path} {schedule.teamTwoName}/>
                     </div>
                     <p className='location text-center mt-3'><i className='fas fa-location-dot mx-1'></i>{schedule.location}</p>
                    </div>
                </div>
            </div>
        </div>
                );
             })}

      
        </div>
      </div>
    </>
  )
}
