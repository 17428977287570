import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';
export default function Inning1bowling() {
    const {mid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/scoreCard/getScoreCard?matchId=`+mid+`&clubId=16685`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
                <div className='inning-detail-box'>
                {value && 
            Object.values(value.data).slice(1,2).map((inni,i) =>{
                    return(             
                   <h5 className='inning-1-head'>Bowling</h5>
                   
                    )
            })
          }
                <table className="table">
                        <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">O</th>
                        <th scope="col">M</th>
                        <th scope="col">Dot</th>
                        <th scope="col">R</th>
                        <th scope="col">W</th>
                        <th scope="col">Econ</th>
                        <th></th>
                        </tr>
                    </thead>
          {value && 
            Object.values(value.data.innings1.bowling).map((inning,i) =>{
                let x = (inning.balls/6).toFixed(1);    
                let balls = inning.balls;
                let round = Math.round(balls / 6-0.4);
                let mod=balls%6;
                let over = parseFloat(round+"."+mod);
                return(             
                    <tbody>
                        <tr>
                        <td><Link to={'/playerdetail/'+inning.playerID}>{inning.profilepic_file_path ? (
               <img src={'https://cricclubs.com'+inning.profilepic_file_path}/>
               ) : (
                <img src='/dummyimg.png'/>
               )
              }  
                          {inning.firstName} {inning.lastName}</Link></td>
                        <td>{over}</td>
                        <td>{inning.maidens}</td>
                        <td>{inning.dotBalls}</td>
                        <td>{inning.runs}</td>
                        <td>{inning.wickets}</td>
                        <td>{(inning.runs/x).toFixed(2)}</td>
                        </tr>
                    </tbody>
                   
                    )
            })
          }
            </table>
            </div>
           
         
        </>
    )
}
