import React from 'react'

export default function Gallery2009() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2009/2009-1.jpg'/>
            </div>
          </div>
        </div>
        </div> 
    </>
  )
}
