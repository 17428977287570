import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';

export default function ANCLSeriesDetails() {
    const {sid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?X-Auth-Token=&clubId=16685&seriesId=`+sid+`&teamId=123`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="red-ball.png"/>
                <span>SERIES</span>
                </div>
            <h1 className='text-center mb-4'><strong>Series Maverickscc Played</strong></h1>
            
          {loading && <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          {value &&
            Object.values(value).map((seriesitem, xd)=>{
              return(   
                <div className='row'>
                    {seriesitem && 
                    Object.values(seriesitem).map((data,x)=>{
                    return(
                        <div className='col-lg-6 my-3'>
                        <div className='series-box' key={xd}>
                        <h3>{data.matchId}</h3>
                        </div>
                        </div>
                    );
                    })}
                   </div>
              );
            })
          }
          </div>
        </>
    )
}
