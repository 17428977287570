import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer>
     <div className='container'>
      <div className='row py-5'>
        <div className='col-lg-3'>
          <img src='/team1.png' />
          <p>Maverickscc - where cricket meets community. Join us in our mission to use cricket as a force
             for good and make a positive impact in the world. Proudly a part of the American Cricket League and the Mavericks Sports Foundation.</p>
              <div className='footer-social-links'>
              <a href='https://www.facebook.com/Mavscc?hc_ref=ARTz_EninRfn5bTa2cVlLWeSC-oalMmtnrFABqjgJ64YMBYh5cPEimz4jAG1Sb22NHY&fref=nf&__xts__[0]=68.ARBVZHVRMOh4G6VHNP3Uoh2jrWBOO2ttWWVbbstCE6LVg5J1qKa5P7S_PXYLZ3k_fCqT3xdvQixtpxCBVN1akMIJkMQeu7mWjeJ8c-Gwe1REQwqxvnVP6GsQJfZGw2IVYpIfku4y6U8e6wuZmOLsO62n3dCK89rLGqIb2sJBF-qDUgXbTAOWEGWGsDd9kCMCYvYZx5899lu1iLwDdeBDPIB9h7HyYedQVCgEk2T_i-8e9m1B1KBsj-hGhcagUdRaqlWhC7erC5LSJcQPWqJyPsSQPUqn' target='_blank'><i className='fab fa-facebook'></i></a>
              <a href='https://www.instagram.com/'><i className='fab fa-instagram'></i></a>
              <a href='https://www.twitter.com/'><i className='fab fa-twitter'></i></a>
              </div>
        </div>
        <div className='col-lg-3'>
          <h3>Quick Links</h3>
          <ul className='footer-links'>
            <li><Link to='/'>Home</Link></li>
            <li>About</li>
            <li>Schedule</li>
            <li>Achievements</li>
            <li>News & Updates</li>
            <li><Link to='team'>Team</Link></li>
            <li>Contact</li>
          </ul>
        </div>

        <div className='col-lg-3'>
          <h3>Other Links</h3>
          <ul className='footer-links'>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
            <li>Locations</li>
            <li><Link to='series'>Series</Link></li>
          </ul>
        </div>
        <div className='col-lg-3'>
          <h3>Contact Information</h3>
          <ul className='footer-links'>
            <li><i className='fas fa-phone px-2'></i><a href='tel:+15712250046'>+1 (571) 225-0046</a></li>
            <li><i className='fas fa-envelope px-2'></i><a href='mailto:admin@maverickscc.com'>admin@maverickscc.com</a></li>
            <li><i className='fas fa-globe px-2'></i>Washington, DC</li>
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='copyright'>
          <p>© Copyright 2023 Maverickscc.com. All Rights Reserved.</p>
        </div>
      </div>
     </div>
    </footer>
      
    </>
  )
}
