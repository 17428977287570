import React from 'react'

export default function Slider() {
  return (
    <>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-12 px-0'>
            <div id="demo" className="carousel slide custom-slider" data-bs-ride="carousel">
  
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>

  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="bg1.jpg" alt="Los Angeles" className="d-block w-100"/>
      <div className='carousel-caption'>
         <div className='top-heading'>
            <img src="red-ball.png"/>
            <span>MAVERICKSS</span>
         </div>
           <h2 className='text-light'>Experience the Thrill of Cricket with Maverickcc</h2>
           <p className='text-white py-2'>Join us for exciting matches, a supportive
            team environment, and a passion for cricket that drives us to be the best.</p>  
      </div>
    </div>
    <div className="carousel-item">
      <img src="2009-main.jpg" alt="Chicago" className="d-block w-100"/>
      <div className='carousel-caption'>
         <div className='top-heading'>
            <img src="red-ball.png"/>
            <span>MAVERICKSS</span>
         </div>
           <h2 className='text-light'>Get Ready for the Ultimate Cricket Experience with Maverickcc</h2>
           <p className='text-white py-2'>We are committed to promoting the sport of cricket and developing
            our players' skills, offering a thrilling cricket experience for players and fans alike.</p>  
      </div>
    </div>
    <div className="carousel-item">
      <img src="bg1.jpg" alt="New York" className="d-block w-100"/>
      <div className='carousel-caption'>
         <div className='top-heading'>
            <img src="red-ball.png"/>
            <span>MAVERICKSS</span>
         </div>
           <h2 className='text-light'>Join the Cricketing Revolution with Maverickcc</h2>
           <p className='text-white py-2'>From friendly matches to intense competitions,
            our club offers an unforgettable cricket experience for players of all levels and ages.</p>  
      </div>
    </div>
  </div>

  
  <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span className="carousel-control-next-icon"></span>
  </button>
</div>
            </div>
        </div>
    </div>
    

    </>
  )
}
