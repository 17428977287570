import React from 'react'
import { Link } from 'react-router-dom'

export default function News() {
  return (
    <>
      <div className='blog-post-section'>
      <div className='container'>
            <div className='row my-5'>
            <div className='col-md-6 col-lg-4  event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
                <div className='col-md-6 col-lg-4  event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
                <div className='col-md-6 col-lg-4  event-card'>
                <div className="card m-auto m-lg-0 m-md-0 rounded-0">
                 <img src="img-1.jpg" className="card-img-top rounded-0"/>
                    <div className="card-body">
                    <h5 className="card-title">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <Link to='/blog-1' className="event-btn">More Detail</Link>
                    </div>
                 </div>
                </div>
            </div>
      </div>
      </div> 
    </>
  )
}
