import {React, useRef}from 'react'
import { Link } from 'react-router-dom';

export default function Header(){
    const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }
  return (
    <>
            {/* Topbar */}
            <div className='top-bar'>
                <div className='container'>
                    <div className='row'>
                        <div className='contact-info-col col-lg-4 col-md-4 col-sm-4 d-none d-md-block d-lg-block d-sm-none'>
                            {/* <a href='/'><i className="fa fa-phone" aria-hidden="true"></i>+1 (571) 225-0046</a> */}
                            <a href="/"><i className="fa fa-envelope" aria-hidden="true"></i>admin@maverickscc.com</a>
                        </div> 
                        <div className='col-lg-4 col-md-5 col-sm-12 welcome-text'>
                                <p className='text-center'>Welcome To Mavericks Sports Foundation</p>    
                        </div>
                        <div className='social-icons-col col-lg-4 col-md-3 col-sm-4 d-none d-md-block d-lg-block d-sm-none'>
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className='main-menu-sec'>
                <div className='container'>
                <div className='row'>
                    <div className='col-md-9 col-lg-10 col-sm-12 py-1 logo-col'>
                    <nav className="navbar navbar-expand-lg py-0">
                    <a href="/" className="navbar-brand">
                    <img alt="..." src='/team1.png'/>
                    </a>
                    <button  ref={navButton} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div ref={linksContainerRef} className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <ul className='nav'>
                            <li className='nav-item'>
                                <Link onClick={collapseNav} className='nav-link' to="/">Home</Link>
                            </li>
                            {/* <li className='nav-item'>
                                <Link className='nav-link' to="schedule">Schedule</Link>
                            </li> */}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Schedule
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link onClick={collapseNav} className="dropdown-item" to="schedule">American Cricket League</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Series
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link onClick={collapseNav} className="dropdown-item" to="anclseries">American Cricket League</Link>
                                </div>
                            </li>

                            <li className='nav-item'>
                                <Link onClick={collapseNav} className='nav-link' to="achievements">Achievements</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Teams
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link onClick={collapseNav} className="dropdown-item" to="redzod-team">Redzod Team</Link>
                                {/* <Link onClick={collapseNav} className="dropdown-item" to="anclteam">DCLU Team</Link> */}
                                </div>
                            </li>
                            <li className='nav-item'>
                                <Link onClick={collapseNav} className='nav-link' to="gallery">Gallery</Link>
                            </li>
                            <li className='nav-item'>
                                <Link onClick={collapseNav} className='nav-link' to="/news">News & Updates</Link>
                            </li>
                            <li className='nav-item'>
                                <Link onClick={collapseNav}  className='nav-link' to="contact">Contact</Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </nav>
                    </div>

                    <div className='col-md-3 col-lg-2 d-flex align-items-center justify-content-end d-none d-md-flex d-lg-flex d-sm-none'>
                        <a className='theme-btn' href="/">JOIN US</a>
                    </div>
                </div>
                </div>
            </div>
            </>
  );
}

