import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

export default function ANCLTeam() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamPlayers?clubId=16685&teamId=123`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

  return (
    <>
      <div className='container my-5'>
         <div className='row'>
      {loading && <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      {value &&
        Object.values(value.data.teamPlayers).map((dataitems, xd)=>{
          return(   
            <div className='col-lg-3 col-md-3 col-6 my-3'>
              <div className="card player-card" key={xd}>
               {dataitems.profilepic_file_path ? (
               <img src={'https://cricclubs.com'+dataitems.profilepic_file_path} className="card-img-top players-img"/>
               ) : (
                <img src='dummyimg.png' className="card-img-top players-img"/>
               )
              }        
              <div className="card-body">
              <h5 className="card-title">{dataitems.firstName} {dataitems.lastName}</h5>
              <p className="card-text">{dataitems.playingRole}</p>
              <Link to={'/playerdetail/'+dataitems.playerID}>View Detail</Link>
              </div>
            </div>
          </div>
      
            // <div key={xd}>     
            //       <span>{dataitems.firstName}</span>            
            // </div>
          );
        })
      }
      </div>
      </div>
    </>
)
}

