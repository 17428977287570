import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';


export default function ANCL2021YearSchedule() {
   
    return (
        <>
          <div className='container my-5'>
          <div className='top-heading m-auto'>
                <img src="/red-ball.png"/>
                <span>2021 Schedule</span>
                </div>
            <h1 className='text-center mb-4'><strong>ANCL Schedule For Maverickscc </strong></h1>
             <div className='row justify-content-center'>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/6/67">
                <h3>2021-SUMMER-PLAYOFF</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2021</p>
                <p><strong>Start Date: </strong>10/09/2021</p>
                <span></span>
                </Link>
                </div>
                </div>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/5/59">
                <h3>2021-SUMMER-LEAGUE</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2021</p>
                <p><strong>Start Date: </strong>06/18/2021</p>
                <span></span>
                </Link>
                </div>
                </div>                               
            </div>

            <div className='row justify-content-center'>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/2/32">
                <h3>PRACTICE GAMES</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2021</p>
                <p><strong>Start Date: </strong>03/14/2021</p>
                <span></span>
                </Link>
                </div>
                </div>      
                <div className='col-lg-5 my-3'>
                <div className='series-box'>
                <Link to="/schedule-matches/1/10">
                <h3>2021-T20-LEAGUE</h3>
                <p><strong>Series Type: </strong>Twenty20</p>
                <p><strong>Year: </strong>2021</p>
                <p><strong>Start Date: </strong>04/01/2021</p>
                <span></span>
                </Link>
                </div>
                </div>                               
            </div>
             
          </div>
        </>
    )
}
