import React from 'react'

export default function Home() {
  return (
    <>
    <div className='history-sec'>
    <div className='container'>
      <div className='row align-items-center'>
        <div className='col-md-12 text-center'>      
            <div className='top-heading m-auto'>
              <img src="red-ball.png"/>
              <span>BACKGROUND</span>
          </div>
          <h2>Maverick's History</h2>
          <p className='history-text m-auto py-2'>The Mavericks Sports Foundation is a registered non-profit 
          organization dedicated to using the sport of cricket to make a positive impact on the community.
           Our cricket club participates in various leagues and events to promote the sport of cricket and 
           support our mission. We also organize our own events and tournaments to bring together local 
           businesses, organizations, and individuals who share our passion for the sport. As a registered
            non-profit organization, we are committed to transparency and accountability, and we strive to 
            create opportunities for young people to participate in sports and access top-notch facilities and coaching. Through our love of cricket and our commitment 
          to giving back, we hope to inspire others to join us in making a positive difference in the world.
            </p>
        </div>
      </div>
    </div>
    </div>
    </>
  )
}
