import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Extras from './Extras';
import Extras2 from './Extras2';
import Inning1 from './Inning1';
import Inning1bowling from './Inning1bowling';
import Inning2bowling from './Inning2bowling';
import Inning1total from './Inning1total';
import Inning2 from './Inning2';
import Inning2total from './Inning2total';
import FullMatchDetailHead from './FullMatchDetailHead';

export default function Full_Match_Details() {
   
    const {mid} = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch(`https://ccapi.cricclubs.com/CCAPI/scoreCard/getScoreCard?matchId=`+mid+`&clubId=16685`)
        .then((response) => response.json())
        .then((actualData) => setValue(actualData))
        .catch((err) => {
          setError(err.message);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    
    return (
        
        <>
        <div className='container'> 
        <FullMatchDetailHead matchid={mid}/>    
            <div className='row my-5'>
            <div className='col-lg-7'>
            <Inning1/>
            </div>
            <div className='col-lg-5'>
            <Inning1bowling/>
            <div className='row'>
                <div className='col-lg-6'>
                <Inning1total/>
                </div>
                <div className='col-lg-6'>
                <Extras/>
                </div>
            </div>
            </div>
            </div>

            <div className='row my-5'>
            <div className='col-lg-7'>
            <Inning2/>
            </div>
            <div className='col-lg-5'>
            <Inning2bowling/>
            <div className='row'>
                <div className='col-lg-6'>
                <Inning2total/>
                </div>
                <div className='col-lg-6'>
                <Extras2/>
                </div>
            </div>
            </div>
            </div>
        </div>
        </>
    )
}
