import React from 'react'

export default function Gallery2014() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='gallery-imgs'>
              <img src='/gallery/2014/2014-1.jpg'/>
              <img src='/gallery/2014/2014-2.jpg'/>
              <img src='/gallery/2014/2014-3.jpg'/>
              <img src='/gallery/2014/2014-4.jpg'/>
              <img src='/gallery/2014/2014-5.jpg'/>
              <img src='/gallery/2014/2014-6.jpg'/>
              <img src='/gallery/2014/2014-7.jpg'/>
              <img src='/gallery/2014/2014-8.jpg'/>
              <img src='/gallery/2014/2014-9.jpg'/>
              <img src='/gallery/2014/2014-10.jpg'/>
              <img src='/gallery/2014/2014-11.jpg'/>
              <img src='/gallery/2014/2014-12.jpg'/>
              <img src='/gallery/2014/2014-13.jpg'/>
              <img src='/gallery/2014/2014-14.jpg'/>
              <img src='/gallery/2014/2014-15.jpg'/>
              <img src='/gallery/2014/2014-16.jpg'/>
              <img src='/gallery/2014/2014-17.jpg'/>
              <img src='/gallery/2014/2014-18.jpg'/>
              <img src='/gallery/2014/2014-19.jpg'/>
              <img src='/gallery/2014/2014-20.jpg'/>
              <img src='/gallery/2014/2014-21.jpg'/>
              <img src='/gallery/2014/2014-22.jpg'/>
            </div>
          </div>
        </div>
        </div> 
    </>
  )
}
